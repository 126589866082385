import React from "react";

import Login from "../../components/./auth/Login";

const LoginPage = () => {

    return (
        <div>
            <h1>Login Page</h1>
            <Login/>
        </div>
    )
}

export default LoginPage