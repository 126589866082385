import React, {useState} from "react";
import axios, {AxiosResponse} from "axios";

import {collegesEndpoint} from "../../utils/endpoints";
import {
    CollegeSearchHitInterface,
    CollegeSearchInterface
} from "../../utils/interfaces";
import {fetchCollegeList} from "../../utils/fetchers";

import closePath from "../../assets/close.svg"
import searchPath from "../../assets/search.svg"
import {Link} from "react-router-dom";

interface SearchCollegesProps {
    updateColleges(colleges: CollegeSearchHitInterface[]): void

    changePaginationVisibility(visibility: boolean): void
}

const SearchPlayers = ({updateColleges, changePaginationVisibility}: SearchCollegesProps) => {
    const [inputText, setInputText] = useState<string>('')

    const search = (query: string) => {
        console.log(query)
    }
    // const search = async (query: string): Promise<PlayerSearchHitInterface[]> => {
    //     if (!query) {
    //         changePaginationVisibility(true)
    //         const res = await fetchPlayerList(`${baseEndpoint}/stuff`)
    //         return res.results
    //     } else {
    //         changePaginationVisibility(false)
    //         const res: AxiosResponse<PlayerSearchInterface> = await axios.get(`${baseEndpoint}/search/players/`, {
    //             params: {
    //                 q: query
    //             }
    //         })
    //         return res.data.hits
    //     }
    // }

    // const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    //     event.preventDefault()
    //     setInputText(event.currentTarget.value)
    //     const query = event.currentTarget.value
    //     try {
    //         const data: PlayerSearchHitInterface[] = await search(query)
    //         updatePlayers(data)
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        setInputText(event.currentTarget.value)
        const query = event.currentTarget.value
        search(query)
    }

    // const handleClear = async () => {
    //     setInputText('')
    //     try {
    //         const data: PlayerSearchHitInterface[] = await search('')
    //         updatePlayers(data)
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    const handleClear = () => {
        setInputText('')
    }

    return (
        <div className="bg-gradient-to-b from-indigo-500 from-10% to-blue-400 py-6">
            <div className="flex flex-col gap-6 items-center">
                <div>
                    <p className="text-4xl font-bold text-gray-50 mb-3">Find Colleges on GTS</p>
                    <p className="text-lg text-gray-200 text-center">Searches are limited to NCAA teams</p>
                </div>
                <div className="flex flex-col gap-1 w-full px-5 md:w-5/6 lg:w-2/3">
                    <div className="flex bg-white rounded-xl items-center text-gray-700 w-full">
                        <img src={searchPath} alt="Search Icon" className="size-6 ml-3"/>
                        <input type="text" placeholder="Search NCAA teams..." autoComplete="off" spellCheck="false" autoCorrect="off"
                               id="query"
                               onChange={handleChange} value={inputText}
                               className="outline-none py-3 ml-3 text-2xl rounded-xl w-full flex items-center justify-center placeholder:text-xl"/>
                        {inputText ? <img src={closePath} alt="Cancel Search" onClick={handleClear}
                                          className="size-8 mr-2 cursor-pointer"/> : <></>}
                    </div>
                    <p className="text-sm w-full mx-2">*If you see any mistakes with teams, players or matches, report
                        them <Link
                            to="/contact" className="cursor-pointer underline text-blue-900">here</Link>.</p>
                </div>
            </div>
        </div>
    )
}

export default SearchPlayers