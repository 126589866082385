import React from "react";

interface CollegePaginationProps {
    loadMoreColleges(): void
    moreCollegesLoading: boolean
}

const CollegePagination = ({loadMoreColleges, moreCollegesLoading}: CollegePaginationProps) => {
    return (
        <div className="flex justify-center items-center p-5">
            <button className="bg-gradient-to-r from-indigo-500 to-blue-500 text-gray-100 px-5 py-3 text-lg rounded" onClick={loadMoreColleges}>{moreCollegesLoading ? "Loading more players..." : "Load More Players"}</button>
        </div>
    )
}

export default CollegePagination