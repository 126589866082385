import React, {useState, useEffect} from "react";
import {Outlet} from "react-router"
import {Link, NavLink} from "react-router-dom";

import menuPath from "./assets/menu.svg"
import closePath from "./assets/close.svg"
import logoPath from "./assets/logo.png"
import searchPath from "./assets/search.svg"
import backArrowPath from "./assets/back-arrow.svg"


function App() {
    const [windowWidth, setWindowWidth] = useState<number>(window.innerHeight)
    const [sidebarVisibility, setSidebarVisibility] = useState<boolean>(false)
    const [globalSearchText, setGlobalSearchText] = useState<string>("")
    const [showTopSearchBar, setShowTopSearchBar] = useState(false)

    useEffect(() => {
        setWindowWidth(window.innerWidth)
        const handleResize = () => {
            setWindowWidth(window.innerWidth)
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const navbarToggle = () => {
        setSidebarVisibility(prev => !prev)
    }

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        setGlobalSearchText(event.currentTarget.value)
    }

    const handleResetSearch = () => {
        setGlobalSearchText("")
        setShowTopSearchBar(false)
    }

    return (
        <div>
            <nav className="flex items-center justify-between py-4 px-3 md:px-6 bg-white sticky top-0 shadow z-10">
                <div className={`flex items-center gap-6 text-lg ${showTopSearchBar ? "invisible" : ""}`}>
                    <Link to="" className="flex items-center gap-2 cursor-pointer">
                        <p className="text-2xl text-indigo-500 font-medium">Global Tennis Score</p>
                        <img src={logoPath} alt="Logo" className="size-10"/>
                    </Link>
                    {windowWidth > 900 ? (
                        <>
                            <NavLink to=""
                                     className={({isActive}) => `group transition-all duration-300 ease-in-out ${isActive ? "text-indigo-500" : ""}`}>
                            <span
                                className="bg-left-bottom bg-gradient-to-r from-indigo-500 to-blue-400 bg-[length:0%_2px] bg-no-repeat group-hover:bg-[length:100%_2px] transition-all duration-500 ease-out">
                                Home
                            </span>
                            </NavLink>
                            <NavLink to="players"
                                     className={({isActive}) => `group transition-all duration-300 ease-in-out ${isActive ? "text-indigo-500" : ""}`}>
                            <span
                                className="bg-left-bottom bg-gradient-to-r from-indigo-500 to-blue-400 bg-[length:0%_2px] bg-no-repeat group-hover:bg-[length:100%_2px] transition-all duration-500 ease-out">
                                Players
                            </span>
                            </NavLink>
                            <NavLink to="colleges"
                                     className={({isActive}) => `group transition-all duration-300 ease-in-out ${isActive ? "text-indigo-500" : ""}`}>
                            <span
                                className="bg-left-bottom bg-gradient-to-r from-indigo-500 to-blue-400 bg-[length:0%_2px] bg-no-repeat group-hover:bg-[length:100%_2px] transition-all duration-500 ease-out">
                                Colleges
                            </span>
                            </NavLink>
                        </>
                    ) : <></>}
                </div>
                <div
                    className={`flex items-center ${windowWidth > 625 ? "gap-6" : "gap-4"} ${showTopSearchBar ? "invisible" : ""}`}>
                    {windowWidth > 625 ? (
                        <div
                            className="flex justify-center border-b border-gray-200 pb-2 focus-within:border-indigo-500">
                            <img src={searchPath} alt="Search Icon" className="size-6 mr-2"/>
                            <input onChange={handleChange} value={globalSearchText} type="text" placeholder="Search"
                                   autoComplete="off" spellCheck="false" autoCorrect="off" id="global-search"
                                   className="outline-none"/>
                            <img onClick={() => setGlobalSearchText("")} src={closePath} alt="Cancel Search"
                                 className={`size-6 ml-2 ${globalSearchText ? "cursor-pointer" : "invisible"}`}/>
                        </div>
                    ) : (
                        <div onClick={() => setShowTopSearchBar(true)}
                             className={`${showTopSearchBar ? "invisible" : ""}`}>
                            <img src={searchPath} alt="Search Icon" className="cursor-pointer size-6"/>
                        </div>
                    )}
                    <div onClick={navbarToggle} className={`cursor-pointer ${showTopSearchBar ? "invisible" : ""}`}>
                        <img src={sidebarVisibility ? closePath : menuPath} alt="Menu" className="size-8"/>
                    </div>
                </div>
                {showTopSearchBar ? (
                    <div className="flex items-center">
                        <img src={backArrowPath} alt="Back arrow" onClick={() => setShowTopSearchBar(false)}
                             className="absolute left-0 ml-3 size-8 cursor-pointer transition transform hover:scale-125 motion-reduce:transition-none motion-reduce:hover:transform-none"/>
                        <div className="absolute right-1/2 translate-x-1/2 w-2/3">
                            <div
                                className="flex justify-center border-b border-gray-200 pb-2 focus-within:border-indigo-500 w-full">
                                <img src={searchPath} alt="Search Icon" className="size-6 mr-2"/>
                                <input onChange={handleChange} value={globalSearchText} type="text" placeholder="Search"
                                       autoComplete="off" spellCheck="false" id="global-search" autoFocus
                                       className="outline-none w-full"/>
                                <img onClick={handleResetSearch} src={closePath} alt="Close Search"
                                     className="size-6 ml-2 cursor-pointer"/>
                            </div>
                        </div>
                    </div>
                ) : <></>}
            </nav>
            <nav
                className={`flex flex-col items-center fixed h-full transition-transform ease-in-out right-0 z-40 ${
                    sidebarVisibility ? "translate-x-0" : "translate-x-full"} bg-indigo-500 h-full w-5/6 md:w-1/2 lg:w-1/4 text-white text-lg`}>
                <div className="flex flex-col w-full">
                    <NavLink to=""
                             className={({isActive}) => `w-full text-center p-4 hover:bg-indigo-400 ${isActive ? "underline" : ""}`}
                             onClick={navbarToggle}>Home
                    </NavLink>
                    <NavLink to="players"
                             className={({isActive}) => `w-full text-center p-4 hover:bg-indigo-400 ${isActive ? "underline" : ""}`}
                             onClick={navbarToggle}>Players
                    </NavLink>
                    <NavLink to="colleges"
                             className={({isActive}) => `w-full text-center p-4 hover:bg-indigo-400 ${isActive ? "underline" : ""}`}
                             onClick={navbarToggle}>Colleges
                    </NavLink>
                </div>
                <hr className="border-gray-50 w-4/5"></hr>
                <div className="flex flex-col w-full">
                    <NavLink to="about"
                             className={({isActive}) => `w-full text-center p-4 hover:bg-indigo-400 ${isActive ? "underline" : ""}`}
                             onClick={navbarToggle}>About
                    </NavLink>
                    <NavLink to="contact"
                             className={({isActive}) => `w-full text-center p-4 hover:bg-indigo-400 ${isActive ? "underline" : ""}`}
                             onClick={navbarToggle}>Contact
                    </NavLink>
                    <NavLink to="faq"
                             className={({isActive}) => `w-full text-center p-4 hover:bg-indigo-400 ${isActive ? "underline" : ""}`}
                             onClick={navbarToggle}>FAQ
                    </NavLink>
                    <NavLink to="coming-soon"
                             className={({isActive}) => `w-full text-center p-4 hover:bg-indigo-400 ${isActive ? "underline" : ""}`}
                             onClick={navbarToggle}>Coming
                        Soon
                    </NavLink>
                </div>
            </nav>
            {sidebarVisibility ? (
                <div onClick={navbarToggle} className="fixed h-full w-full bg-black bg-opacity-50 z-30"></div>
            ) : <></>}
            <div>
                <Outlet/>
            </div>
        </div>
    )
        ;
}


export default App;
