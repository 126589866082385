import React from "react";

const ComingSoon = () => {
    return (
        <div className="flex flex-col items-center gap-0">
            <div className="bg-indigo-500 w-full py-3 px-12 md:px-3">
                <p className="text-2xl font-bold text-center text-gray-50">Coming Soon</p>
            </div>
            <div className="flex flex-col w-full">
                <div className="py-6 px-6 md:px-12 flex flex-col items-start bg-white text-black">
                    <p className="font-bold text-xl">More Player and Matches on our Database</p>
                </div>
                <div className="py-6 px-6 md:px-12 flex flex-col items-start bg-white text-black">
                    <p className="font-bold text-xl">Search Filters</p>
                    <div className="flex flex-col gap-3 md:w-2/3">
                        <p>These will make searching for players or viewing rankings easier.</p>
                        <ul className="list-disc ml-6 flex flex-col gap-2">
                            <li><span className="font-bold">Filter by GTS</span></li>
                            <li><span className="font-bold">Filter by age</span></li>
                            <li><span className="font-bold">Filter by gender</span></li>
                            <li><span className="font-bold">Filter by nationality</span></li>
                        </ul>
                    </div>
                </div>
                <div className="py-6 px-6 md:px-12 flex flex-col items-start bg-white text-black">
                    <p className="font-bold text-xl">Rating History and Graphs</p>
                    <div className="flex flex-col gap-3 md:w-2/3">
                        <p>There will soon be graphs to visualize player progression over time.</p>
                    </div>
                </div>
                <div className="py-6 px-6 md:px-12 flex flex-col items-start bg-white text-black">
                    <p className="font-bold text-xl">Accounts</p>
                    <div className="flex flex-col gap-3 md:w-2/3">
                        <p>You will have the opportunity to make your own account on GTS. This will come with the
                            following opportunities:</p>
                        <ul className="list-disc ml-6 flex flex-col gap-2">
                            <li><span className="font-bold">Report your own matches</span></li>
                            <li><span className="font-bold">Individualized ratings</span></li>
                        </ul>
                    </div>
                </div>
                <div className="py-6 px-6 md:px-12 flex flex-col items-start bg-white text-black">
                    <p className="font-bold text-xl">See Upcoming Tournaments and Matches</p>
                    <div className="flex flex-col gap-3 md:w-2/3">
                        <p>You will see scheduled matches that haven't been played yet.</p>
                    </div>
                </div>
                <div className="py-6 px-6 md:px-12 flex flex-col items-start bg-white text-black">
                    <p className="font-bold text-xl">Mobile App</p>
                    <div className="flex flex-col gap-3 md:w-2/3">
                        <p>A mobile app will be coming for a smoother user experience.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ComingSoon