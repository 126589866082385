import React from "react";
import ReactDOM from "react-dom/client";
import {RouterProvider} from "react-router-dom"

import "./index.css"

import {router} from "./routes/Routes";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <RouterProvider router={router}/>
    </React.StrictMode>
);
