import {createBrowserRouter} from "react-router-dom"

import App from "../App";
import HomePage from "../pages/home/HomePage";
import AboutGTS from "../pages/promotion/AboutGTS";
import FAQ from "../pages/promotion/FAQ";
import ComingSoon from "../pages/promotion/ComingSoon";
import ContactGTS from "../pages/promotion/ContactGTS";

import LoginPage from "../pages/login/LoginPage";
import PlayerDetailPage from "../pages/players/PlayerDetailPage";
import CollegeHomePage from "../pages/colleges/CollegeHomePage";
import PlayerHomePage from "../pages/players/PlayerHomePage";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
        children: [
            {path: "", element: <HomePage/>},
            {path: "players", element: <PlayerHomePage/>},
            {path: "colleges", element: <CollegeHomePage/>},
            {path: "about", element: <AboutGTS/>},
            {path: "faq", element: <FAQ/>},
            {path: "coming-soon", element: <ComingSoon/>},
            {path: "contact", element: <ContactGTS/>},
            {path: "login", element: <LoginPage/>},
            {path: "players/:id", element: <PlayerDetailPage/>}
        ]
    }
])