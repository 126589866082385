import {playersEndpoint} from "./endpoints";
import axios, {AxiosResponse} from "axios";
import {CollegeListPaginationInterface, PlayerDetailInterface, PlayerListPaginationInterface} from "./interfaces";

export const fetchPlayerList = async (endpoint: string): Promise<PlayerListPaginationInterface> => {
    const res: AxiosResponse<PlayerListPaginationInterface> = await axios.get(endpoint, {
        // headers: {
        //     "Authorization": `Bearer ${localStorage.getItem("access")}`
        // }
    })

    return res.data
}

export const fetchPlayerDetail = async (id: string): Promise<PlayerDetailInterface> => {
    const endpoint = `${playersEndpoint}/${id}/`
    const res: AxiosResponse<PlayerDetailInterface> = await axios.get(endpoint, {})
    return res.data
}

export const fetchCollegeList = async (endpoint: string): Promise<CollegeListPaginationInterface> => {
    const res: AxiosResponse<CollegeListPaginationInterface> = await axios.get(endpoint, {
        // headers: {
        //     "Authorization": `Bearer ${localStorage.getItem("access")}`
        // }
    })

    return res.data
}