import React from "react";

interface PlayerPaginationProps {
    loadMorePlayers(): void
    morePlayersLoading: boolean
}

const PlayerPagination = ({loadMorePlayers, morePlayersLoading}: PlayerPaginationProps) => {
    return (
        <div className="flex justify-center items-center p-5">
            <button className="bg-gradient-to-r from-indigo-500 to-blue-500 text-gray-100 px-5 py-3 text-lg rounded" onClick={loadMorePlayers}>{morePlayersLoading ? "Loading more players..." : "Load More Players"}</button>
        </div>
    )
}

export default PlayerPagination