import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useParams} from "react-router";
import {whereAlpha3} from "iso-3166-1";

import PlayerDetailMatch from "../../components/players/PlayerDetailMatch";
import Error from "../../components/general/Error";
import Modal from "../../components/general/Modal";

import {fetchPlayerDetail} from "../../utils/fetchers";
import {MatchForPlayerDetailInterface, PlayerDetailInterface} from "../../utils/interfaces";
import backArrowPath from "../../assets/back-arrow-light.svg"

const PlayerDetailPage = () => {
    const [loading, setLoading] = useState<boolean>(true)
    const [error, setError] = useState<boolean>(false)
    const [showModal, setShowModal] = useState<boolean>(false)
    const [modalHeader, setModalHeader] = useState<string>('')
    const [modalMessage, setModalMessage] = useState<React.ReactNode>('')

    const [player, setPlayer] = useState<PlayerDetailInterface | null>(null)
    const [gender, setGender] = useState<string | null>(null)
    const [nationality, setNationality] = useState<string | null>(null)
    const [tournamentsState, setTournamentsState] = useState<MatchForPlayerDetailInterface[][]>([])

    const [confidence, setConfidence] = useState<string | null>(null)
    const [confidenceBg, setConfidenceBg] = useState<string>('bg-green-500')

    const [volatility, setVolatility] = useState<string | null>(null)
    const [volatilityBg, setVolatilityBg] = useState<string>('bg-green-500')

    const {id} = useParams()

    useEffect(() => {
        if (id) {
            fetchPlayerDetail(id).then(data => {
                setLoading(false)
                setPlayer(data)
                document.title = data.name + " - GTS"

                // format gender
                if (data.gender === 'g') setGender('Girl')
                else setGender('Boy')

                // format nationality
                if (data.nationality === 'USA') setNationality('United States')
                else if (data.nationality === 'RUS') setNationality('Russia')
                else if (data.nationality === 'GER') setNationality('Germany')
                else if (data.nationality === 'IRI') setNationality('Iran')
                else if (data.nationality === 'PAR') setNationality('Paraguay')
                else if (data.nationality === 'NED') setNationality('Netherlands')
                else if (data.nationality === 'GBR') setNationality('Great Britain')
                else if (data.nationality === 'BUL') setNationality('Bulgaria')
                else if (data.nationality === 'LAT') setNationality('Latvia')
                else {
                    const playerNationality = whereAlpha3(data.nationality)?.country
                    if (playerNationality) setNationality(playerNationality)
                    else setNationality(data.nationality)
                }

                // confidence rating
                const rd = parseInt(data.rd)
                if (rd >= 300) {
                    setConfidence('None')
                    setConfidenceBg("bg-black")
                } else if (rd < 300 && rd >= 250) {
                    setConfidence('Low')
                    setConfidenceBg("bg-red-400")
                } else if (rd < 250 && rd >= 175) {
                    setConfidence('Medium')
                    setConfidenceBg("bg-orange-400")
                } else {
                    setConfidence('High')
                    setConfidenceBg("bg-green-500")
                }

                // volatility rating
                const vol = parseFloat(data.volatility)
                if (vol <= 0.06) {
                    setVolatility('High')
                    setVolatilityBg('bg-green-500')
                } else if (vol > 0.06 && vol < 0.06007) {
                    setVolatility('Medium')
                    setVolatilityBg('bg-orange-400')
                } else {
                    setVolatility('Low')
                    setVolatilityBg('bg-red-400')
                }

                // organize matches by tournament
                const matches = data.matches
                let tournaments: MatchForPlayerDetailInterface[][] = []
                if (matches) {
                    if (matches.length === 1) {
                        tournaments.push(matches)
                    } else {
                        let previousTournament: string = matches[0].tournament
                        let previousTournamentDate: string = matches[0].date
                        let previousTournamentMatches: MatchForPlayerDetailInterface[] = [matches[0]]

                        for (let i = 0; i < data.matches.length; i++) {
                            if (i > 0) {
                                if (previousTournament === matches[i].tournament && previousTournamentDate === matches[i].date) {
                                    previousTournamentMatches.push(matches[i])
                                } else {
                                    tournaments.push(previousTournamentMatches)  // add to all tournaments array

                                    previousTournament = matches[i].tournament  // reset the stuff
                                    previousTournamentDate = matches[i].date
                                    previousTournamentMatches = [matches[i]]
                                }
                                if (i === data.matches.length - 1) {
                                    tournaments.push(previousTournamentMatches)
                                }
                            }
                        }
                    }
                }
                setTournamentsState(tournaments)
            }).catch(error => {
                setLoading(false)
                setError(true)
                console.log(error)
            })
        }
    }, [id]);


    const toggleModal = (header: string, message: React.ReactNode) => {
        setModalHeader(header)
        setModalMessage(message)
        setShowModal(prev => !prev)
    }

    return (
        <>
            {showModal ? <Modal header={modalHeader} message={modalMessage} close={() => toggleModal("", "")}/> : <></>}
            {loading ? <div
                className="absolute h-3/4 w-full bg-gradient-to-b from-indigo-500 from-10% to-blue-400"></div> : <></>}
            {error ? (
                <Error message="Error loading player"/>
            ) : (
                <div key={player?.id}>
                    <div
                        className="bg-gradient-to-b from-indigo-500 from-10% to-blue-400 flex flex-col items-center p-6 gap-5">
                        <div className="flex flex-col items-center">
                            {/*<Link to="/"*/}
                            {/*      className="flex gap-0 items-center absolute left-0 ml-0 md:ml-3 transition transform hover:scale-110 motion-reduce:transition-none motion-reduce:hover:transform-none">*/}
                            {/*    <img src={backArrowPath} alt="Back arrow"*/}
                            {/*         className="size-8"/>*/}
                            {/*    <p className="text-gray-50 font-light">Back</p>*/}
                            {/*</Link>*/}
                            <p className="text-2xl font-bold text-gray-50 text-center">{player?.name}</p>
                            <p className="text-lg font-light text-gray-100">{nationality} | {gender} {player?.age ? `| ${player.age} years old` : ''}
                            </p>
                        </div>
                        <div className="grid grid-cols-2 gap-6 border-white border py-3 px-6 rounded">
                            <div className="flex flex-col items-center">
                                <div className="flex items-center justify-center gap-2">
                                    <p className="text-xl text-gray-100">GTS</p>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         strokeWidth="2"
                                         stroke="currentColor"
                                         className="h-5 w-5 cursor-pointer stroke-gray-200 hover:stroke-gray-400"
                                         onClick={() => toggleModal("Global Tennis Score", <p>A player's Global Tennis
                                             Score (GTS) is a <span className="font-bold">rating based on their tennis level</span>,
                                             similar to UTR and
                                             WTN. Learn more about GTS <Link to="/about#gts"
                                                                             className="cursor-pointer text-blue-500 underline">here</Link> and
                                             learn more about the advantages of GTS <Link to="/about#advantages"
                                                                                          className="cursor-pointer text-blue-500 underline">here</Link>.
                                         </p>)}>
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                              d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"></path>
                                    </svg>
                                </div>
                                <p className="text-4xl font-bold text-gray-50">{player?.rating}</p>
                            </div>
                            <div className="flex flex-col items-center">
                                <div className="flex items-center justify-center gap-2">
                                    <p className="text-xl text-gray-100">CUTR</p>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         strokeWidth="2"
                                         stroke="currentColor"
                                         className="h-5 w-5 cursor-pointer stroke-gray-200 hover:stroke-gray-400"
                                         onClick={() => toggleModal("CUTR", <p>As GTS is a new rating system,
                                             you will be unfamiliar with how GTS correlates to level of play. The
                                             CUTR shows a player's rating on a 16 point scale. Learn
                                             more <Link to="/about#converted-utr"
                                                        className="cursor-pointer text-blue-500 underline">here</Link>
                                             .</p>)}>
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                              d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"></path>
                                    </svg>
                                </div>
                                <p className="text-4xl font-bold text-gray-50">{player?.predicted_utr}</p>
                            </div>
                            <div className="flex flex-col items-center gap-1 text-center">
                                <div className="flex items-center justify-center gap-2">
                                    <p className="text-xl text-gray-100">Confidence</p>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         strokeWidth="2"
                                         stroke="currentColor"
                                         className="h-5 w-5 cursor-pointer stroke-gray-200 hover:stroke-gray-400"
                                         onClick={() => toggleModal("Rating Confidence", <p>There are three levels of
                                             confidence for a player's GTS rating: <ul className="list-disc ml-6 my-1">
                                                 <li className="font-bold">High</li>
                                                 <li className="font-bold">Medium</li>
                                                 <li className="font-bold">Low</li>
                                             </ul>These levels indicate how certain our algorithm is for a player's
                                             rating.
                                             It depends on the number of matches they have played. Learn more <Link
                                                 to="/about#confidence"
                                                 className="cursor-pointer text-blue-500 underline">here</Link>.</p>)}>
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                              d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"></path>
                                    </svg>
                                </div>
                                <div
                                    className={`${confidenceBg} py-0.5 px-2 w-full h-min rounded text-xl font-bold text-gray-50`}>
                                    <p>{confidence}</p>
                                </div>
                            </div>
                            <div className="flex flex-col items-center gap-1 text-center">
                                <div className="flex items-center justify-center gap-2">
                                    <p className="text-xl text-gray-100">Consistency</p>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         strokeWidth="2"
                                         stroke="currentColor"
                                         className="h-5 w-5 cursor-pointer stroke-gray-200 hover:stroke-gray-400"
                                         onClick={() => toggleModal("Player Consistency", <p>A player will fall into one
                                             of three consistency categories: <ul className="list-disc ml-6 my-1">
                                                 <li className="font-bold">High</li>
                                                 <li className="font-bold">Medium</li>
                                                 <li className="font-bold">Low</li>
                                             </ul>These levels indicate how volatile the level of a player is. A highly
                                             volatile player will have large fluctuations in their level depending on
                                             the match/day. Learn more <Link
                                                 to="/about#consistency"
                                                 className="cursor-pointer text-blue-500 underline">here</Link>.</p>)}>
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                              d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"></path>
                                    </svg>
                                </div>
                                <div
                                    className={`${volatilityBg} py-0.5 px-2 w-full h-min rounded text-xl font-bold text-gray-50`}>
                                    <p>{volatility}</p>
                                </div>
                            </div>
                            <div className="flex flex-col items-center">
                                <div className="flex items-center gap-2">
                                    <p className="text-xl text-gray-100">{player?.gender === 'b' ? 'Boys ' : 'Girls '} Rank</p>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         strokeWidth="2"
                                         stroke="currentColor"
                                         className="h-5 w-5 cursor-pointer stroke-gray-200 hover:stroke-gray-400"
                                         onClick={() => toggleModal("Gender Only Rank", <p>The gender only rank is a
                                             player's rank (by GTS) only counting players with the same gender. Learn
                                             more <Link
                                                 to="/about#rank"
                                                 className="cursor-pointer text-blue-500 underline">here</Link>.</p>)}>
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                              d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"></path>
                                    </svg>
                                </div>
                                <p className="text-4xl font-bold text-gray-50">{player?.gender_rank}</p>
                            </div>
                            <div className="flex flex-col items-center">
                                <div className="flex items-center gap-2">
                                    <p className="text-xl text-gray-100">Total Rank</p>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         strokeWidth="2"
                                         stroke="currentColor"
                                         className="h-5 w-5 cursor-pointer stroke-gray-200 hover:stroke-gray-400"
                                         onClick={() => toggleModal("Total Rank", <p>The total rank <span
                                             className="font-bold">is a player's rank
                                             (by GTS) counting all players</span> - both boys and girls.
                                             Unlike UTR which has boys and girls on the same scale, GTS separates the
                                             genders. This makes it easier to assess the dominance of all players,
                                             because a 1500 for a boy will have the same relative level to other boys as
                                             a
                                             1500 girl will have relative to other girls. To place them on the same
                                             scale look at their converted UTR's. Learn more <Link
                                                 to="/about#rank"
                                                 className="cursor-pointer text-blue-500 underline">here</Link>.</p>)}>
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                              d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"></path>
                                    </svg>
                                </div>
                                <p className="text-4xl font-bold text-gray-50">{player?.total_rank}</p>
                            </div>
                        </div>
                    </div>
                    <div className="pb-6 flex flex-col gap-4">
                        {tournamentsState.map(tournament => (
                            <div key={tournament[0].tournament_id} className="mx-4 mt-2">
                                <div className="flex items-center justify-between border-indigo-500 border-b">
                                    <p className="text-md font-medium">{tournament[0].tournament}</p>
                                    <p className="font-light text-gray-600 text-xs">{new Date(tournament[0].date).toLocaleDateString('en-US', {
                                        year: 'numeric',
                                        month: 'short',
                                        day: 'numeric'
                                    })} | {tournament[0].surface.charAt(0).toUpperCase() + tournament[0].surface.slice(1)} | {tournament[0].category}</p>
                                </div>
                                <div className="flex flex-col gap-0">
                                    {tournament.map(m => (
                                        <PlayerDetailMatch key={m.id} player={player} match={m}/>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </>
    )
}

export default PlayerDetailPage