import React, {useRef, useEffect} from "react";
import {Link, useLocation} from "react-router-dom";

const AboutGTS = () => {
    const advantagesRef = useRef(null)
    const gtsRef = useRef(null)
    const convertedUtrRef = useRef(null)
    const confidenceRef = useRef(null)
    const consistencyRef = useRef(null)
    const rankRef = useRef(null)
    const {hash} = useLocation();

    useEffect(() => {
        if (hash === '#advantages' && advantagesRef.current) {
            // @ts-ignore
            advantagesRef.current.scrollIntoView({behavior: 'smooth'});
        } else if (hash === '#gts' && gtsRef.current) {
            // @ts-ignore
            gtsRef.current.scrollIntoView({behavior: 'smooth'});
        } else if (hash === '#converted-utr' && convertedUtrRef.current) {
            // @ts-ignore
            convertedUtrRef.current.scrollIntoView({behavior: 'smooth'});
        } else if (hash === '#confidence' && confidenceRef.current) {
            // @ts-ignore
            confidenceRef.current.scrollIntoView({behavior: 'smooth'});
        } else if (hash === '#consistency' && consistencyRef.current) {
            // @ts-ignore
            consistencyRef.current.scrollIntoView({behavior: 'smooth'});
        } else if (hash === '#rank' && rankRef.current) {
            // @ts-ignore
            rankRef.current.scrollIntoView({behavior: 'smooth'});
        }
    }, [hash]);

    return (
        <div className="flex flex-col items-center gap-0">
            <div ref={advantagesRef} className="bg-indigo-500 w-full py-3 px-12 md:px-3">
                <p className="text-2xl font-bold text-center text-gray-50">About GTS</p>
                <p className="text-center text-gray-50">We are a tennis rating website similar to UTR and WTN.</p>
            </div>
            <div className="flex flex-col w-full">
                {/*<div className="py-6 px-6 md:px-12 flex flex-col items-start bg-white text-black">*/}
                {/*    <p className="font-bold text-xl">GTS Is Here to Replace UTR and WTN</p>*/}
                {/*    <div className="flex flex-col gap-3 md:w-2/3">*/}
                {/*        <p>UTR and WTN issues:</p>*/}
                {/*        <ul className="list-disc ml-6 flex flex-col gap-2">*/}
                {/*            <li><span className="font-bold">Their website/app are ugly and slow.</span>*/}
                {/*            </li>*/}
                {/*            <li><span className="font-bold">You have to pay for UTR power to see ratings to 2 decimal places.</span>*/}
                {/*            </li>*/}
                {/*            <li><span*/}
                {/*                className="font-bold">UTR has ruined the new generation of junior tennis.</span>*/}
                {/*            </li>*/}
                {/*            <li><span className="font-bold">College coaches only look at a players's UTR now. They don't take the time to see you play.</span>*/}
                {/*            </li>*/}
                {/*            <li><span className="font-bold">Some countries/regions have hyper-inflated UTR's.</span>*/}
                {/*            </li>*/}
                {/*            <li ref={advantagesRef}><span className="font-bold">UTR is taking away from local tennis leagues.</span>*/}
                {/*            </li>*/}
                {/*        </ul>*/}
                {/*        <p>GTS rectifies all these problems that UTR and WTN have.</p>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="py-6 px-6 md:px-12 flex flex-col items-start bg-white text-black">
                    <div className="md:w-2/3">
                        <p className="font-bold text-xl mb-3">Advantages of GTS</p>
                        <ul className="list-disc flex flex-col gap-2 ml-6">
                            <li><span className="font-bold">Free.</span> GTS is completely free. And, there is no need
                                to create an account.
                            </li>
                            <li><span className="font-bold">Globally calibrated</span> - At the moment, the GTS
                                algorithm
                                has only included global ITF matches in our algorithm. This ensures that there are no
                                geographical discrepancies in a player's rating, unlike UTR.
                            </li>
                            <li><span className="font-bold">Less obsession.</span> Ratings only update on the tenth day
                                of
                                each month. There is no more checking your UTR every day to see small changes.
                            </li>
                            <li><span className="font-bold">Winning matters.</span> The GTS algorithm prioritizes
                                winning.
                                There is no need to be going into a match thinking about the amount of games you need to
                                win
                                by to improve your GTS. The score in a match can only help. Winning by 7-6, 6-7, 7-6 or
                                6-0,
                                6-0
                                will give the same rating boost to the winner. On the other hand, the score does help
                                the
                                loser if they make the match close. So just treat matches like they were before UTR -
                                try to
                                win and get as many games possible.
                            </li>
                            <li><span className="font-bold">Player consistency.</span> GTS evaluates how consistent a
                                player is. A player's consistency indicates the degree of fluctuation in their level.
                            </li>
                            <li ref={gtsRef}><span className="font-bold">Relatable conversion to other ratings.</span> GTS ratings are on
                                a different
                                scale. There is a conversion to a 16 point scale.
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="py-6 px-6 md:px-12 flex flex-col items-end bg-indigo-500 text-gray-200">
                    <div className="md:w-2/3">
                        <p className="font-bold text-xl mb-3">The GTS Rating System</p>
                        <ul className="list-disc flex flex-col gap-2 ml-6">
                            <li><span className="font-bold">Rating algorithm.</span> GTS is a modified version of
                                the <a target="_blank"
                                       rel="noreferrer"
                                       href="http://www.glicko.net/glicko/glicko2.pdf"
                                       className="text-blue-300 underline cursor-pointer">Glicko-2
                                    Rating System</a>.
                            </li>
                            <li><span className="font-bold">Rating intervals.</span> The minimum GTS is 0. There is
                                no
                                max, but it is very unlikely that any player will exceed 2500.
                            </li>
                            <li><span className="font-bold">1500 Default.</span> All new players start at 1500.
                            </li>
                            <li><span className="font-bold">Time period.</span> GTS weights more recent matches more
                                heavily.
                            </li>
                            <li><span className="font-bold">Score weighting.</span> Winning matters most. Score is no
                                hurt for the winner. The loser can benefit from a close score.
                            </li>
                            <li><span className="font-bold">Updates.</span> Ratings are updated once
                                a month from ITF
                                tournaments in the previous month. If you didn't play that month your rating confidence
                                decreases.
                            </li>
                            <li ref={convertedUtrRef} id="converted-utr"><span className="font-bold">Problems.</span> GTS is very new. Unfortunately, this leads
                                to some problems. The GTS algorithm
                                has only been run on matches since January 2024. This means, a very short time ago every
                                ITF player started at 1500 and there have not been many matches to adjust players'
                                ratings
                                yet. So, a player who wins only J30's could have a GTS much higher than it should be. A
                                player who has only played Junior Grand Slam's but lost in the first round each time may
                                have a very low GTS. GTS is also limited to only ITF junior singles matches at the
                                moment. We have a lot planned though! See all the future updates <Link to="/coming-soon"
                                                                                                       className="cursor-pointer text-blue-300 underline">here</Link>.
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="py-6 px-6 md:px-12 flex flex-col items-start bg-white text-black">
                    <div className="md:w-2/3">
                        <p className="font-bold text-xl mb-3">CUTR</p>
                        <ul className="list-disc flex flex-col gap-2 ml-6">
                            <li><span className="font-bold">This is entirely based on the GTS rating system.</span>
                            </li>
                            <li ref={confidenceRef}><span className="font-bold">Converts.</span> It merely converts a player's GTS (which is
                                new and unfamiliar) to a more relatable 16 point format.
                            </li>
                        </ul>
                    </div>
                </div>
                <div id="confidence" className="py-6 px-6 md:px-12 flex flex-col items-end bg-indigo-500 text-gray-200">
                    <div className="md:w-2/3">
                        <p className="font-bold text-xl mb-3">GTS Confidence</p>
                        <ul className="list-disc flex flex-col gap-2 ml-6">
                            <li>Rating confidence indicates how certain GTS is in a player's rating.
                            </li>
                            <li><span className="font-bold">Three levels of confidence:</span> High, medium, and low.
                            </li>
                            <li><span className="font-bold">Simplicity.</span> GTS assigns these qualitative labels for
                                confidence. Under the hood there are far more complex calculations to determine rating
                                deviations. If you would like to see these quantitative values on the website,
                                please <Link to="/contact" className="cursor-pointer underline text-blue-300">let us
                                    know</Link>.
                            </li>
                            <li id="consistency" ref={consistencyRef}><span className="font-bold">Factors.</span> In
                                general, more matches played means a
                                higher confidence. However, large deviations in results (a 2000 losing to a 1000) will
                                decrease the confidence (not to be confused with <a href="#consistency"
                                                                                    className="cursor-pointer text-blue-300 underline">consistency</a>).
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="py-6 px-6 md:px-12 flex flex-col items-start bg-white text-black">
                    <div className="md:w-2/3">
                        <p className="font-bold text-xl mb-3">Player Consistency</p>
                        <ul className="list-disc flex flex-col gap-2 ml-6">
                            <li>Consistency indicates the level of fluctuation in a player's level.
                            </li>
                            <li><span className="font-bold">Three levels of consistency:</span> High, medium, and low.
                            </li>
                            <li><span className="font-bold">Simplicity.</span> GTS assigns these qualitative labels for
                                consistency. Under the hood there are far more complex calculations to determine
                                consistency. If you would like to see these quantitative values on the website,
                                please <Link to="/contact" className="cursor-pointer underline text-blue-500">let us
                                    know</Link>.
                            </li>
                            <li ref={rankRef}><span className="font-bold">Factors.</span> Beating players rated well
                                above you or
                                losing to players well below you will decrease your consistency.
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="py-6 px-6 md:px-12 flex flex-col items-end bg-indigo-500 text-gray-200">
                    <div className="md:w-2/3">
                        <p className="font-bold text-xl mb-3">Player Ranks</p>
                        <ul className="list-disc flex flex-col gap-2 ml-6">
                            <li>We give each player two ranks.
                            </li>
                            <li><span className="font-bold">Gender specific rank.</span> This rank only includes players
                                in the selected player's gender.
                            </li>
                            <li><span className="font-bold">Total rank.</span> This includes all players - boys and
                                girls. This does not compare player's level directly but it compares their dominance.
                                This is because GTS has two separate scales, one for boys and one for girls. A 2500
                                girl is more dominant against other girls than a 2000 boy is against other boys.
                                Therefore, in the total rank, the 2500 girl will be higher. If you would like to compare
                                their levels directly, look at their <a href="#converted-utr"
                                                                        className="text-blue-300 cursor-pointer underline">CUTRs</a> as they will be different.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutGTS