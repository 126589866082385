import React from "react";

const ContactGTS = () => {
    return (
        <div className="flex flex-col items-center gap-0">
            <div className="bg-indigo-500 w-full py-3 px-12 md:px-3">
                <p className="text-2xl font-bold text-center text-gray-50">Contact GTS</p>
                <p className="text-center text-gray-100">Feel free to contact us with any questions, data errors, or
                    anything else of concern.</p>
            </div>
            <div className="flex flex-col w-full">
                <div className="py-6 px-6 md:px-12 flex flex-col items-start bg-white text-black">
                    <p className="font-bold text-xl">Email</p>
                    <div className="flex flex-col gap-3 md:w-2/3">
                        <p>Email GTS at <a href="mailto:globaltenisscore@gmail.com" className="cursor-pointer text-blue-500 underline">globaltenisscore@gmail.com</a></p>
                    </div>
                </div>
                <div className="py-6 px-6 md:px-12 flex flex-col items-start bg-white text-black">
                    <p className="font-bold text-xl">Instagram</p>
                    <div className="flex flex-col gap-3 md:w-2/3">
                        <p>Contact GTS on instagram <a href="https://www.instagram.com/globaltennisscore"
                                                               className="cursor-pointer text-blue-500 underline" target="_blank" rel="noreferrer">@globaltennisscore</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactGTS