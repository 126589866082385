import React from "react";

interface ErrorPropsInterface {
    message: string
}

const Error = ({message}: ErrorPropsInterface) => {
    return (
        <div className="flex justify-center p-6">
            <div
                className="flex items-center text-lg bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                     stroke="currentColor" className="h-5 w-5 mr-2">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"></path>
                </svg>
                <p>{message}</p>
            </div>
        </div>
    )
}

export default Error