import React, {useEffect, useState} from 'react';

import closePath from "../../assets/close.svg"

interface ModalPropsInterface {
    header: string
    message: React.ReactNode;
    close: () => void;
}

const Modal = ({header, message, close}: ModalPropsInterface) => {
    const [visible, setVisible] = useState<boolean>(false)

    useEffect(() => {
        setVisible(true)
    }, []);

    const closeModal = () => {
        setVisible(false)
        setTimeout(() => {
            close()
        }, 100)
    }

    return (
        <>
            <div
                className={`fixed left-1/2 -translate-x-1/2 z-50 w-full md:w-fit px-6 transition-transform duration-150 ease-in-out ${visible ? "translate-y-12": "-translate-y-full"}`}
            >
                <div className="flex flex-col gap-3 p-6 bg-white rounded">
                    <div className="flex gap-3 justify-between items-center">
                        <p className="font-bold text-lg">{header}</p>
                        <img src={closePath} alt="Close message" className="cursor-pointer size-8" onClick={closeModal}/>
                    </div>
                    {message}
                </div>
            </div>
            <div className="fixed inset-0 bg-black bg-opacity-50 z-40" onClick={closeModal}></div>
        </>
    );
};

export default Modal;
