import React from "react";
import {Link} from "react-router-dom";

const FAQ = () => {
    return (
        <div className="flex flex-col items-center gap-0">
            <div className="bg-indigo-500 w-full py-3 px-12 md:px-3">
                <p className="text-2xl font-bold text-center text-gray-50">GTS FAQs</p>
            </div>
            <div className="flex flex-col w-full">
                <div className="py-6 px-6 md:px-12 flex flex-col items-start bg-white text-black">
                    <p className="font-bold text-xl">No Questions Yet</p>
                    <div className="flex flex-col gap-3 md:w-2/3">
                        <p>Contact us <Link to="/contact"
                                            className="cursor-pointer text-blue-500 underline">here</Link> and we'll
                            answer any questions you have.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FAQ