import React from "react";
import axios, {AxiosResponse} from "axios";
import {useNavigate} from "react-router-dom";

import baseEndpoint from "../../utils/endpoints";
import {TokenObtainPairInterface} from "../../utils/interfaces";

interface FormElements extends HTMLFormControlsCollection {
    username: HTMLInputElement
    password: HTMLInputElement
}

interface LoginFormElement extends HTMLFormElement {
    readonly elements: FormElements
}

const Login = () => {
    const navigate = useNavigate();

    const obtainTokenPair = async (username: string, password: string): Promise<TokenObtainPairInterface> => {
        const endpoint = `${baseEndpoint}/token/`
        const res: AxiosResponse<TokenObtainPairInterface> = await axios.post(endpoint, {
            username, password
        })
        return res.data
    }

    const handleSubmit = async (event: React.FormEvent<LoginFormElement>) => {
        event.preventDefault()
        const form = event.currentTarget
        const username = form.elements.username.value
        const password = form.elements.password.value

        try {
            const data: TokenObtainPairInterface = await obtainTokenPair(username, password)
            localStorage.setItem("access", data.access)
            localStorage.setItem("refresh", data.refresh)
            navigate("/")
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div>
            <h1>Login</h1>
            <form onSubmit={handleSubmit}>
                <label htmlFor="username"><b>Username</b></label>
                <input type="text" id="username"/>

                <label htmlFor="password"><b>Password</b></label>
                <input type="password" id="password"/>

                <button type="submit">Login</button>
            </form>
        </div>
    )
}

export default Login