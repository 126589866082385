import React, {useEffect, useState} from "react";
import {MatchForPlayerDetailInterface, PlayerDetailInterface} from "../../utils/interfaces";
import {Link} from "react-router-dom";

interface PlayerDetailMatchProps {
    player: PlayerDetailInterface | null
    match: MatchForPlayerDetailInterface
}

const PlayerDetailMatch = ({player, match}: PlayerDetailMatchProps) => {
    const [won, setWon] = useState<boolean | null>(null)
    const [playerIndex, setPlayerIndex] = useState<number>(0)
    const [opponentIndex, setOpponentIndex] = useState<number>(1)
    const [score, setScore] = useState<[number, number, number | null][] | null>(null)
    const [playerScoreIndex, setPlayerScoreIndex] = useState<number>(0)
    const [opponentScoreIndex, setOpponentScoreIndex] = useState<number>(1)
    const [round, setRound] = useState<string | null>(null)
    const [drawType, setDrawType] = useState<string | null>(null)

    useEffect(() => {
        // when clicking links to players, need to scroll to top
        window.scrollTo(0, 0)

        if (player) {
            // setting winner
            let varWon: boolean

            if (player.id === match.winner) {
                setWon(true)
                varWon = true
            } else {
                setWon(false)
                varWon = false
            }

            // finding index for players in players.matches array
            if (match.players[0].id === player.id) {
                setPlayerIndex(0)
                setOpponentIndex(1)
            } else {
                setPlayerIndex(1)
                setOpponentIndex(0)
            }

            // parsing score from string to JS array
            let scoreString = match.score
            scoreString = scoreString.replace(/None/g, "null")
            const parsedScore = JSON.parse(scoreString)
            setScore(parsedScore)

            // finding index for players in score array
            let pos0Sets: number = 0
            let pos1Sets: number = 0
            for (let i = 0; i < parsedScore.length; i++) {
                if (parsedScore[i][0] > parsedScore[i][1]) {
                    pos0Sets++
                } else {
                    pos1Sets++
                }
            }

            let posWinner: number;
            let posLoser: number;
            if (pos0Sets > pos1Sets) {
                posWinner = 0
                posLoser = 1
            } else {
                posWinner = 1
                posLoser = 0
            }
            if (varWon) {
                setPlayerScoreIndex(posWinner)
                setOpponentScoreIndex(posLoser)
            } else {
                setPlayerScoreIndex(posLoser)
                setOpponentScoreIndex(posWinner)
            }

            // formatting rounds
            const roundWords = match.round.split(' ')
            if (roundWords[1] === 'round') setRound(roundWords[0] + ' Round')
            else if (match.round === 'final') setRound('Finals')
            else setRound(match.round.charAt(0).toUpperCase() + match.round.slice(1))

            // formatting draw type
            if (match.draw_type === 'q') setDrawType('Qualifying draw')
            else setDrawType('Main draw')
        }
        // eslint-disable-next-line
    }, [match]);

    const formatName = (name: string): string => {
        const nameWords = name.split(' ')
        const firstName = nameWords[0][0] + '.'
        const lastName = nameWords.slice(1).join(' ')
        return firstName + ' ' + lastName
    }

    return (
        <div className="mt-2">
            <div className="flex gap-1">
                <p className="mb-1 text-gray-800 text-sm">{round} | {drawType}</p>
            </div>
            <div className="flex gap-2">
                <div
                    className={`flex justify-center items-center w-10 rounded text-lg text-white font-bold ${won ? "bg-green-400" : "bg-red-400"}`}>
                    <p>{won ? "W" : "L"}</p>
                </div>
                <div className="bg-gray-100 rounded py-3 px-3 w-full">
                    <div className="flex items-center">
                        <div className="text-md w-full">
                            <p className={won ? "font-bold" : "font-regular"}>{formatName(match.players[playerIndex].name)}
                                <span className="ml-2 font-thin text-sm">{match.players[playerIndex].rating}</span></p>
                            <Link to={`/players/${match.players[opponentIndex].id}`}
                                  className={won ? "font-regular" : "font-bold"}>{formatName(match.players[opponentIndex].name)}
                                <span
                                    className="ml-2 font-thin text-sm">{match.players[opponentIndex].rating}</span></Link>
                        </div>
                        <div className="flex gap-2 justify-end text-md">
                            {score?.map((set, i) => (
                                <div key={i} className={`flex flex-col ${set[2] === null ? "items-center" : ""}`}>
                                    <p>{set[playerScoreIndex]}{set[2] !== null && set[playerScoreIndex] !== 7 ?
                                        <sup>{set[2]}</sup> : ''}</p>
                                    <p>{set[opponentScoreIndex]}{set[2] !== null && set[opponentScoreIndex] !== 7 ?
                                        <sup>{set[2]}</sup> : ''}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PlayerDetailMatch