import React, {useEffect, useState} from "react";

import CollegeList from "../../components/colleges/CollegeList";
import SearchColleges from "../../components/colleges/SearchColleges";
import CollegePagination from "../../components/colleges/CollegePagination";
import Error from "../../components/general/Error";

import {CollegeSearchHitInterface} from "../../utils/interfaces";
import {fetchCollegeList, fetchPlayerList} from "../../utils/fetchers";
import {collegesEndpoint} from "../../utils/endpoints";

const HomePage = () => {
    const [loading, setLoading] = useState<boolean>(true)
    const [moreCollegesLoading, setMoreCollegesLoading] = useState<boolean>(false)
    const [error, setError] = useState<boolean>(false)

    const [colleges, setColleges] = useState<CollegeSearchHitInterface[]>([])
    const [collegeCount, setCollegeCount] = useState<number>(0)
    const [nextCollegesUrl, setNextCollegesUrl] = useState<string | null>(null)
    const [showPagination, setShowPagination] = useState<boolean>(true)

    useEffect(() => {
        document.title = "Colleges - GTS"

        fetchCollegeList(collegesEndpoint).then(data => {
            setLoading(false)
            setColleges(data.results)
            setCollegeCount(data.count)
            setNextCollegesUrl(data.next)
        })
            .catch(error => {
                setLoading(false)
                setError(true)
                console.log(error)
            })
    }, []);

    const updateColleges = (colleges: CollegeSearchHitInterface[]) => {
        setColleges(colleges)
    }

    const loadMoreColleges = () => {
        if (!nextCollegesUrl) {
            return
        }
        setMoreCollegesLoading(true)
        fetchPlayerList(nextCollegesUrl).then(data => {
            setMoreCollegesLoading(false)
            setColleges(existingColleges => [...existingColleges, ...data.results])
            setNextCollegesUrl(data.next)

        }).catch(error => {
            setMoreCollegesLoading(false)
            setError(true)
            console.log(error)
        })
    }

    const changePaginationVisibility = (visibility: boolean) => {
        setShowPagination(visibility)
    }

    return (
        <div>
            <SearchColleges updateColleges={updateColleges} changePaginationVisibility={changePaginationVisibility}/>
            {loading ? <></> : (
                <>
                    {error ? (
                        <Error message="Error loading colleges"/>
                    ) : (
                        <>
                            <CollegeList colleges={colleges} count={collegeCount}/>
                            {showPagination ? <CollegePagination loadMoreColleges={loadMoreColleges}
                                                                moreCollegesLoading={moreCollegesLoading}/> : <></>}
                        </>
                    )}
                </>
            )}
        </div>
    )
}


export default HomePage