// computer endpoints for server
// const baseEndpoint = "http://localhost:8000/api"
//
// export const playersEndpoint = "http://localhost:8000/api/users/players"

// phone endpoints for server at Toronto
// const baseEndpoint = 'http://10.0.0.22:8000/api'
//
// export const playersEndpoint = 'http://10.0.0.22:8000/api/users/players'

// collingwood phone endpoints
// const baseEndpoint = 'http://192.168.1.13:8000/api'
//
// export const playersEndpoint = 'http://192.168.1.13:8000/api/users/players'

// production endpoints
const baseEndpoint = "https://tennis-rating.onrender.com/api"
export const searchEndpoint = `${baseEndpoint}/search`
export const playersEndpoint = `${baseEndpoint}/users/players`
export const collegesEndpoint = `${baseEndpoint}/colleges`

export default baseEndpoint